'use client'

import Head from 'next/head'
import useUser from '../lib/useUser'

export default function Page() {
  useUser({
    redirectTo: '/',
    redirectIfFound: false,
  })

  return (
    <Head>
      <title>Login</title>
      <meta name="description" content="Generated by create next app" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.ico" />
    </Head>
  )
}
